import Element from "../../domain/Element/Element";
import ElementGatewayInterface from "../../domain/Element/ElementGatewayInterface";
import envVariable from "../../presentation/util/envVariable";
import Caller from "../../domain/Api/Caller";
import {EDocusignButton} from "../../domain/Docusign/EDocusignButton";
import FormGateway from "../Form/FormGateway";
import {array} from "yup";
import {EElementType} from "../../domain/Element/EElementType";

// ............................... ........................................................................ REMAKE Setup

const VIAGER_RATES_TABLE_ATTRIBUTES_OPTIONS = {
    header: ["Age de l'usufruitier", "Usufruit", "Nue-propriété"],
    options: [
        {
            value: "20",
            label: "Moins de 20 ans révolus",
            options: [{label: "90%", value: "90"}, {label: "10%", value: "10"}],
            presentation: "Moins de 20 ans révolus"
        },
        {
            value: "21_30",
            label: "De 21 à 30 ans",
            options: [{label: "80%", value: "80"}, {label: "20%", value: "20"}],
            presentation: "De 21 à 30 ans"
        },
        {
            value: "31_40",
            label: "De 31 à 40 ans",
            options: [{label: "70%", value: "70"}, {label: "30%", value: "30"}],
            presentation: "De 31 à 40 ans"
        },
        {
            value: "41_50",
            label: "De 41 à 50 ans",
            options: [{label: "60%", value: "60"}, {label: "40%", value: "40"}],
            presentation: "De 41 à 50 ans"
        },
        {
            value: "51_60",
            label: "De 51 à 60 ans",
            options: [{label: "50%", value: "50"}, {label: "50%", value: "50"}],
            presentation: "De 51 à 60 ans"
        },
        {
            value: "61_70",
            label: "De 61 à 70 ans",
            options: [{label: "40%", value: "40"}, {label: "60%", value: "60"}],
            presentation: "De 61 à 70 ans"
        },
        {
            value: "71_80",
            label: "De 71 à 80 ans",
            options: [{label: "30%", value: "30"}, {label: "70%", value: "70"}],
            presentation: "De 71 à 80 ans"
        },
        {
            value: "81_90",
            label: "De 81 à 90 ans",
            options: [{label: "20%", value: "20"}, {label: "80%", value: "80"}],
            presentation: "De 81 à 90 ans"
        },
        {
            value: "91",
            label: "Plus de 91 ans",
            options: [{label: "10%", value: "10"}, {label: "90%", value: "90"}],
            presentation: "Plus de 91 ans"
        },
    ]
}

export default class FetchElementGateway implements ElementGatewayInterface {

    getVoisinMockedElements(elements, productCode: string, formData): Promise<Element[] | null> {

        const VOISIN_IBAN_ELEMENT_ID = "f3fbabe3-a187-4d8a-b401-5ca1f3e3b7d4"
        const VOISIN_IBAN_1_ELEMENT_ID = "380a8c82-1efb-4339-a5d0-bff7de26112d"
        const VOISIN_L_IBAN_ELEMENT_ID = "cef08ffb-f1ea-4a12-917b-1ca13aa22bd5"
        const VOISIN_L2_IBAN_ELEMENT_ID = "66e39df8-aa9d-4534-9729-3e0f07bcd849"

        const VOISIN_PRODUCT_SHARES_NUMBER_ELEMENT_ID = "06fcb274-9279-11eb-a8b3-0242ac130003";
        const VOISIN_PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID = "06fcb332-9279-11eb-a8b3-0242ac130003";
        const VOISIN_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID = "06fcb526-9279-11eb-a8b3-0242ac130003";
        const VOISIN_PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID = "06fcb5ee-9279-11eb-a8b3-0242ac130003";

        const VOISIN_PRODUCT_CODE_FOR_EP = "EP";
        const VOISIN_PRODUCT_CODE_FOR_IP = "IP";
        const VOISIN_PRODUCT_CODE_FOR_EPE = "EE";

        const VOISIN_EP_PART_UNIT_PRICE_ELEMENT_ID = "06fcafa4-9279-11eb-a8b3-0242ac130003";
        const VOISIN_IP_PART_UNIT_PRICE_ELEMENT_ID = "f36b0d1a-06db-4a5e-a6ea-46350d460942";
        const VOISIN_EPE_PART_UNIT_PRICE_ELEMENT_ID = "258fc818-d1d1-4aa7-b3f8-fd5323f2dcb9";

        const VOISIN_VIAGER_RATES_ELEMENT_ID = "027c92b4-a326-4cfd-8b83-e0e1efe1fb34"

        const VOISIN_TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID = "7904ee25-8ac6-499c-a179-119c3aae973d"
        // Produit Voisin: Epargne Pierre
        const VOISIN_TEMPORARY_DISMEMBERMENT_RATES_EP_RATES_ELEMENT_ID = "66bce220-e3a1-4284-82f0-2499a485e255"
        // Produit Voisin: Immo Placement
        const VOISIN_TEMPORARY_DISMEMBERMENT_RATES_IP_RATES_ELEMENT_ID = ""
        const VOISIN_TEMPORARY_DISMEMBERMENT_RATES_EPE_RATES_ELEMENT_ID = "66bcd220-e3a1-4284-82f0-2499c485f255"


        let CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = ''
        let CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = ''

        switch (productCode) {
            case VOISIN_PRODUCT_CODE_FOR_EP:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = VOISIN_EP_PART_UNIT_PRICE_ELEMENT_ID
                CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = VOISIN_TEMPORARY_DISMEMBERMENT_RATES_EP_RATES_ELEMENT_ID
                break
            case VOISIN_PRODUCT_CODE_FOR_IP:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = VOISIN_IP_PART_UNIT_PRICE_ELEMENT_ID
                CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = VOISIN_TEMPORARY_DISMEMBERMENT_RATES_IP_RATES_ELEMENT_ID
                break
            case VOISIN_PRODUCT_CODE_FOR_EPE:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = VOISIN_EPE_PART_UNIT_PRICE_ELEMENT_ID
                CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = VOISIN_TEMPORARY_DISMEMBERMENT_RATES_EPE_RATES_ELEMENT_ID
                break
            default:
        }

        const chosenProductUnitPriceElement = elements.find(
            (element) => element.id === CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID
        )

        const chosenProductUnitPrice = chosenProductUnitPriceElement !== undefined
            ? chosenProductUnitPriceElement.attributes.defaultValue
            : ''

        return elements.map(
            (item) => {

                let type = item.type
                let attributes = item.attributes
                let condition = item.condition
                let calculate = ''
                let reference = ''

                switch (item.id) {

                    // .................................................... Bouton : Création, Authorisation du prospect
                    case "e176f745-ec12-49d3-92aa-0a8e5b63986c":
                        type = "button_create_prospect"
                        break

                    // ................................................................... Bouton : Imprimer le document
                    case "78979a0d-58be-4b03-95a4-02aa57317f8b":
                        type = EElementType.BUTTON_PRINT
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: false,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: 37
                        }
                        break

                    // ........................................... Bouton : Imprimer Lettre de mission (Parcours direct)
                    case  "071839f8-cb78-40d3-83f4-473354607f15":
                        type = EElementType.BUTTON_PRINT
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: false,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: 36
                        }
                        break

                    // .................................................. Bouton : Signer Entrée de relation (DER et LM)
                    case  "36401f4c-1908-420b-a8ff-f64c02433c0f":
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "80c9da5c-2517-47d3-9239-48b5db040aef" // MISSION_VALIDATION_DOCUSIGN_ID
                        }
                        break

                    // ......................... Bouton : Signer Entrée de relation pour le souscripteur (DER, LM et RI)
                    case  EDocusignButton.MISSION_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "7cf81483-ad33-49e3-9e32-c8e72426b293" // SUBSCRIBER_ENTRY_RELATION_DOCUSIGN_ID
                        }
                        break

                    // ....................... Bouton : Signer Entrée de relation por le co-souscripteur (DER, LM et RI)
                    case  EDocusignButton.MISSION_CO_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "81f4d9d5-67bd-5617-a0d9-5fca6e1f1738"
                        }
                        break

                    // .............. Bouton : Signer Entrée de relation por le conjoint du souscripteur (DER, LM et RI)
                    case EDocusignButton.MISSION_SUBSCRIBER_SPOUSE_SIGN_BUTTON_ELEMENT_ID:
                        /*type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "f4821dca-0c08-5ecb-a633-87da73134a5c" // SUBSCRIBER_SPOUSE_ENTRY_RELATION_DOCUSIGN_ID
                        }*/
                        break

                    //  .................................. Bouton : Signer les documents de soucription pour le souscripteur
                    case  EDocusignButton.SUBSCRIPTION_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "38c959b2-4109-4b8c-ae37-92e402ef19f7" // SUBSCRIBER_CONFIRMATION_DOCUSIGN_ID
                        }
                        break

                    // ............................... Bouton : Signer les documents de souscription pour le co-souscripteur
                    case  EDocusignButton.SUBSCRIPTION_CO_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "36046765-8efd-4bb0-aba6-3d58bd4b268c" // CO_SUBSCRIBER_CONFIRMATION_DOCUSIGN_ID
                        }
                        break

                    // ...................... Bouton : Signer les documents de souscription pour le conjoint du souscripteur
                    case EDocusignButton.SUBSCRIPTION_SUBSCRIBER_SPOUSE_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "53d3e6d9-0d46-4e93-8acb-4f68fec2a037" // SUBSCRIBER_SPOUSE_CONFIRMATION_DOCUSIGN_ID
                        }
                        break

                    // ........ Bouton : Signer les documents de souscription pour cgp (conseiller en gestion de patrimoine)
                    case EDocusignButton.SUBSCRIPTION_SUBSCRIBER_ADVISOR_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "6bf4e0e0-c366-4ed5-b896-356104a089c7" // CGP_CONFIRMATION_DOCUSIGN_ID
                        }
                        break

                    // ........................................................................................ Parcours
                    case "8a924722-bac6-4b3d-bfec-5080a0b4d8a1":
                        type = EElementType.PRESENTATION
                        reference = "693eb07f-b309-4677-90ab-1f9b39d1e92d"
                        break
                    // ............................................................................................. Nom
                    case "a3a12574-06e4-4a87-bfab-ed0de4c69ba2":
                        type = EElementType.PRESENTATION
                        reference = "06fc90c8-9279-11eb-a8b3-0242ac130003"
                        break
                    // .......................................................................................... Prénom
                    case "1ee4f512-ca1e-45b1-b712-0ec08699437b":
                        type = EElementType.PRESENTATION
                        reference = "06fc8e84-9279-11eb-a8b3-0242ac130003"
                        break
                    // ............................................................................... nom de la société
                    case "93b40cc0-a14a-46f7-9cdc-35d2937c8183":
                        type = EElementType.PRESENTATION
                        reference = "6033381f-e075-42fc-a234-0fcee9fc5f6c"
                        break

                    // .......................................................................................... Projet
                    case "4647b8a6-7549-4e7e-9f65-1da7245c9e13":
                        type = EElementType.PRESENTATION
                        reference = "06fca5c2-9279-11eb-a8b3-0242ac130003"
                        break

                    // ............................................................................................ SCPI
                    case "dbb5395d-cc20-4381-b7be-648c667f3d77":
                        type = EElementType.PRESENTATION
                        reference = "06fcae78-9279-11eb-a8b3-0242ac130003"
                        break

                    // ......................................................................................... Montant
                    case "de16fc41-67f0-4726-9bdf-b417561e420e":
                        type = EElementType.PRESENTATION
                        reference = VOISIN_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID
                        break

                    // ............................................................................ Type de démembrement
                    case "ed35c332-b76e-4d15-b7ab-ee38d16b5b17":
                        type = EElementType.PRESENTATION
                        reference = "d35597e6-06b2-4aac-aade-e7e66e0eb17b"
                        break

                    // ......................................................................................... Taux NP
                    case "f5293e3e-55c5-48d7-a57a-2b3a84562352":
                        type = EElementType.PRESENTATION
                        reference = "c415f4b9-cee3-4b21-9760-140f5e15a9dc"
                        break

                    // ........................................................................................ Taux USU
                    case "e66f2554-a3d2-4671-880f-56fbb894d86d":
                        type = EElementType.PRESENTATION
                        reference = "85d55bd0-d3e5-4d90-af44-fa78acc4821e"
                        break

                    // ..................................................................................... Montant USU
                    case "affe1c89-6add-4b55-832d-7a6d889388eb":
                        type = EElementType.PRESENTATION
                        reference = "aa7cb769-37c2-4171-b751-8e8f2475a3b6"
                        break

                    // ...................................................................................... Montant NP
                    case "f5212254-296a-4692-bb69-56925a1dfa24":
                        type = EElementType.PRESENTATION
                        reference = "f64a415f-8a50-4978-ab97-2254352bf891"
                        break

                    // ....................................................................................... Fréquence
                    case "ec0c250b-a61d-46d4-b484-c064459eb810":
                        type = EElementType.PRESENTATION
                        reference = "151908ad-c89d-4657-81a7-f0730cc69ae6"
                        break

                    // .............................................................................. Durée démembrement
                    case "75ea234d-0db6-41d5-ac1c-381b90cf1f8d":
                        type = EElementType.PRESENTATION
                        reference = "7904ee25-8ac6-499c-a179-119c3aae973d"
                        break

                    // ............................................................................... Mode de règlement
                    case "6b6a9c26-a178-4ae2-919a-7e6929d4c149":
                        type = EElementType.PRESENTATION
                        reference = "06fcbe54-9279-11eb-a8b3-0242ac130003"
                        break

                    // ...................................................................................... Montant NP
                    case "e705965a-0428-4d89-9fea-0ae36da7fd12":
                        type = EElementType.PRESENTATION
                        reference = "fe1de194-97ea-4a59-b0a2-1efb9b9d3178"
                        break

                    // ............................................................................. Montant Usufruitier
                    case "80a7f604-3bde-413b-8fde-b5cfa48e6242":
                        type = EElementType.PRESENTATION
                        reference = "ec379366-c2f3-4945-8a93-32ac853f3625"
                        break

                    // ......................................................................................... Taux NP
                    case "73efdfe1-fa54-4200-bb7e-b11960c1ed3e":
                        type = EElementType.PRESENTATION
                        reference = "b912d642-2742-4688-89a3-999335156a34"
                        break

                    // ................................................................................ Taux Usufruitier
                    case "6716fa5a-f474-4441-b933-f9c24428dd57":
                        type = EElementType.PRESENTATION
                        reference = "7153becc-3f25-4598-a118-9cb7261bc468"
                        break

                    case "c8c46ce8-088a-4e40-8946-08350cd94ae4":
                        type = EElementType.PRESENTATION
                        reference = `${VOISIN_PRODUCT_SHARES_NUMBER_ELEMENT_ID}`
                        break


                    // ................................................................ Justificatif d’origine des fonds
                    case "f0980926-dd3a-4838-b2c0-e6bf402733d1":
                        condition = {
                            rules: [
                                {
                                    type: "input",
                                    input_id: "06fcacfc-9279-11eb-a8b3-0242ac130003",
                                    value: "Oui"
                                },
                                {
                                    type: "input",
                                    input_id: VOISIN_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID,
                                    value: 100000,
                                    operator: "greater_than"
                                }
                            ],
                            logical_operator: "or", "actions": {required: false, disabled: false}
                        }
                        break

                    // ............................................................................................ IBAN
                    case VOISIN_IBAN_ELEMENT_ID:
                    case VOISIN_IBAN_1_ELEMENT_ID:
                    case VOISIN_L_IBAN_ELEMENT_ID:
                    case VOISIN_L2_IBAN_ELEMENT_ID:
                        type = EElementType.INPUT_IBAN
                        break

                    // ............................................................. Nombre de parts (en toutes lettres)
                    case VOISIN_PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = `${VOISIN_PRODUCT_SHARES_NUMBER_ELEMENT_ID}`
                        break

                    // .......................................................... Démembrement viager : Tableau dex taux
                    case VOISIN_VIAGER_RATES_ELEMENT_ID:
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement viager",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: VIAGER_RATES_TABLE_ATTRIBUTES_OPTIONS
                        }
                        break

                    // ...................................................... Démembrement viager : Taux Nu-propriétaire
                    case "b912d642-2742-4688-89a3-999335156a34":
                        calculate = "<input_id>027c92b4-a326-4cfd-8b83-e0e1efe1fb34_1</input_id>"
                        break

                    // .......................................... Démembrement viager : Montant unitaire nu-propriétaire
                    case "f12a415f-8a50-4951-ab97-2254342bf891":
                        calculate = "(<input_id>b912d642-2742-4688-89a3-999335156a34</input_id>"
                        calculate += ` / 100) * ${chosenProductUnitPrice}`
                        break

                    // ................................................... Démembrement viager : Montant nu-propriétaire
                    case "f13a415f-8a50-5178-ab97-2254352bf891":
                        calculate = "(<input_id>7153becc-3f25-4598-a118-9cb7261bc468</input_id>"
                        calculate += ` / 100) * ${chosenProductUnitPrice}`
                        break

                    // .......................................................... Démembrement viager : Taux Usufruitier
                    case "7153becc-3f25-4598-a118-9cb7261bc468":
                        calculate = `<input_id>${VOISIN_VIAGER_RATES_ELEMENT_ID}_0</input_id>`
                        break

                    // ....................................................... Démembrement viager : Montant Usufruitier
                    case "ec379366-c2f3-4945-8a93-32ac853f3625":
                        calculate = `(<input_id>${VOISIN_VIAGER_RATES_ELEMENT_ID}_0</input_id>`
                        calculate += ` / 100) * (${chosenProductUnitPrice}`
                        calculate += `* <input_id>${VOISIN_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>)`
                        break

                    // ................................... Démembrement temporaire : Tableau des taux Épargne Pierre (EP)
                    case VOISIN_TEMPORARY_DISMEMBERMENT_RATES_EP_RATES_ELEMENT_ID :
                        if (productCode !== VOISIN_PRODUCT_CODE_FOR_EP) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ["Durée", "Usufruit", "Nue-propriété"],
                                options: [
                                    {
                                        value: "3",
                                        label: "3 ans",
                                        options: [{label: "13%", value: "13"}, {label: "87%", value: "87"}],
                                        presentation: "3 ans"
                                    },
                                    {
                                        value: "4",
                                        label: "4 ans",
                                        options: [{label: "17%", value: "17"}, {label: "83%", value: "83"}],
                                        presentation: "4 ans"
                                    },
                                    {
                                        value: "5",
                                        label: "5 ans",
                                        options: [{label: "21%", value: "21"}, {label: "79%", value: "79"}],
                                        presentation: "5 ans"
                                    },
                                    {
                                        value: "6",
                                        label: "6 ans",
                                        options: [{label: "24%", value: "24"}, {label: "76%", value: "76"}],
                                        presentation: "6 ans"
                                    },
                                    {
                                        value: "7",
                                        label: "7 ans",
                                        options: [{label: "27%", value: "27"}, {label: "73%", value: "73"}],
                                        presentation: "7 ans"
                                    },
                                    {
                                        value: "8",
                                        label: "8 ans",
                                        options: [{label: "29%", value: "29"}, {label: "71%", value: "71"}],
                                        presentation: "8 ans"
                                    },
                                    {
                                        value: "9",
                                        label: "9 ans",
                                        options: [{label: "32%", value: "32"}, {label: "68%", value: "68"}],
                                        presentation: "9 ans"
                                    },
                                    {
                                        value: "10",
                                        label: "10 ans",
                                        options: [{label: "34%", value: "34"}, {label: "66%", value: "66"}],
                                        presentation: "10 ans"
                                    },
                                    {
                                        value: "11",
                                        label: "11 ans",
                                        options: [{label: "35%", value: "35"}, {label: "65%", value: "65"}],
                                        presentation: "11 ans"
                                    },
                                    {
                                        value: "12",
                                        label: "12 ans",
                                        options: [{label: "36%", value: "36"}, {label: "64%", value: "64"}],
                                        presentation: "12 ans"
                                    },
                                    {
                                        value: "13",
                                        label: "13 ans",
                                        options: [{label: "37%", value: "37"}, {label: "63%", value: "63"}],
                                        presentation: "13 ans"
                                    },
                                    {
                                        value: "14",
                                        label: "14 ans",
                                        options: [{label: "38%", value: "38"}, {label: "62%", value: "62"}],
                                        presentation: "14 ans"
                                    },
                                    {
                                        value: "15",
                                        label: "15 ans",
                                        options: [{label: "40%", value: "40"}, {label: "60%", value: "60"}],
                                        presentation: "15 ans"
                                    },
                                    {
                                        value: "16",
                                        label: "16 ans",
                                        options: [{label: "41%", value: "41"}, {label: "59%", value: "59"}],
                                        presentation: "16 ans"
                                    },
                                    {
                                        value: "17",
                                        label: "17 ans",
                                        options: [{label: "42%", value: "42"}, {label: "58%", value: "58"}],
                                        presentation: "17 ans"
                                    },
                                    {
                                        value: "18",
                                        label: "18 ans",
                                        options: [{label: "43%", value: "43"}, {label: "57%", value: "57"}],
                                        presentation: "18 ans"
                                    },
                                    {
                                        value: "19",
                                        label: "19 ans",
                                        options: [{label: "44%", value: "44"}, {label: "56%", value: "56"}],
                                        presentation: "19 ans"
                                    },
                                    {
                                        value: "20",
                                        label: "20 ans",
                                        options: [{label: "45%", value: "45"}, {label: "55%", value: "55"}],
                                        presentation: "20 ans"
                                    },
                                ]
                            }
                        }
                        break

                    // ............................. Démembrement temporaire : Tableau des taux Inter-Pierre France (IP)
                    case VOISIN_TEMPORARY_DISMEMBERMENT_RATES_IP_RATES_ELEMENT_ID:
                        if (productCode !== VOISIN_PRODUCT_CODE_FOR_EP) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement viager",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ["Durée", "Usufruit", "Nue-propriété"],
                                options: [
                                    {
                                        value: "3",
                                        label: "3 ans",
                                        options: [{label: "12.5%", value: "12.5"}, {
                                            label: "87.5%",
                                            value: "87.5"
                                        }],
                                        presentation: "3 ans"
                                    },
                                    {
                                        value: "4",
                                        label: "4 ans",
                                        options: [{label: "16%", value: "16"}, {label: "84%", value: "84"}],
                                        presentation: "4 ans"
                                    },
                                    {
                                        value: "5",
                                        label: "5 ans",
                                        options: [{label: "20%", value: "20"}, {label: "80%", value: "80"}],
                                        presentation: "5 ans"
                                    },
                                    {
                                        value: "6",
                                        label: "6 ans",
                                        options: [{label: "23.5%", value: "23.5"}, {
                                            label: "76.5%",
                                            value: "76.5"
                                        }],
                                        presentation: "6 ans"
                                    },
                                    {
                                        value: "7",
                                        label: "7 ans",
                                        options: [{label: "26%", value: "26"}, {label: "74%", value: "74"}],
                                        presentation: "7 ans"
                                    },
                                    {
                                        value: "8",
                                        label: "8 ans",
                                        options: [{label: "28.5%", value: "28.5"}, {
                                            label: "71.5%",
                                            value: "71.5"
                                        }],
                                        presentation: "8 ans"
                                    },
                                    {
                                        value: "9",
                                        label: "9 ans",
                                        options: [{label: "30%", value: "30"}, {label: "70%", value: "70"}],
                                        presentation: "9 ans"
                                    },
                                    {
                                        value: "10",
                                        label: "10 ans",
                                        options: [{label: "33%", value: "33"}, {label: "67%", value: "67"}],
                                        presentation: "10 ans"
                                    },
                                    {
                                        value: "11",
                                        label: "11 ans",
                                        options: [{label: "34.5%", value: "34.5"}, {
                                            label: "65.5%",
                                            value: "65.5"
                                        }],
                                        presentation: "11 ans"
                                    },
                                    {
                                        value: "12",
                                        label: "12 ans",
                                        options: [{label: "36%", value: "36"}, {label: "64%", value: "64"}],
                                        presentation: "12 ans"
                                    },
                                    {
                                        value: "13",
                                        label: "13 ans",
                                        options: [{label: "37%", value: "37"}, {label: "63%", value: "63"}],
                                        presentation: "13 ans"
                                    },
                                    {
                                        value: "14",
                                        label: "14 ans",
                                        options: [{label: "38%", value: "38"}, {label: "62%", value: "62"}],
                                        presentation: "14 ans"
                                    },
                                    {
                                        value: "15",
                                        label: "15 ans",
                                        options: [{label: "39%", value: "39"}, {label: "61%", value: "61"}],
                                        presentation: "15 ans"
                                    },
                                    {
                                        value: "16",
                                        label: "16 ans",
                                        options: [{label: "40%", value: "40"}, {label: "60%", value: "60"}],
                                        presentation: "16 ans"
                                    },
                                    {
                                        value: "17",
                                        label: "17 ans",
                                        options: [{label: "41%", value: "41"}, {label: "59%", value: "59"}],
                                        presentation: "17 ans"
                                    },
                                    {
                                        value: "18",
                                        label: "18 ans",
                                        options: [{label: "42%", value: "42"}, {label: "58%", value: "58"}],
                                        presentation: "18 ans"
                                    },
                                    {
                                        value: "19",
                                        label: "19 ans",
                                        options: [{label: "43%", value: "43"}, {label: "57%", value: "57"}],
                                        presentation: "19 ans"
                                    },
                                    {
                                        value: "20",
                                        label: "20 ans",
                                        options: [{label: "44%", value: "44"}, {label: "56%", value: "56"}],
                                        presentation: "20 ans"
                                    },
                                ]
                            }
                        }
                        break
                    case VOISIN_TEMPORARY_DISMEMBERMENT_RATES_EPE_RATES_ELEMENT_ID :
                        if (productCode !== VOISIN_PRODUCT_CODE_FOR_EPE) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ["Durée", "Usufruit", "Nue-propriété"],
                                options: [
                                    {
                                        value: "3",
                                        label: "3 ans",
                                        options: [{label: "10.5%", value: "10.5"}, {label: "89.5%", value: "9.5"}],
                                        presentation: "3 ans"
                                    },
                                    {
                                        value: "4",
                                        label: "4 ans",
                                        options: [{label: "14%", value: "14"}, {label: "86%", value: "86"}],
                                        presentation: "4 ans"
                                    },
                                    {
                                        value: "5",
                                        label: "5 ans",
                                        options: [{label: "17%", value: "17"}, {label: "83%", value: "83"}],
                                        presentation: "5 ans"
                                    },
                                    {
                                        value: "6",
                                        label: "6 ans",
                                        options: [{label: "19%", value: "19"}, {label: "81%", value: "81"}],
                                        presentation: "6 ans"
                                    },
                                    {
                                        value: "7",
                                        label: "7 ans",
                                        options: [{label: "22%", value: "22"}, {label: "78%", value: "78"}],
                                        presentation: "7 ans"
                                    },
                                    {
                                        value: "8",
                                        label: "8 ans",
                                        options: [{label: "24%", value: "24"}, {label: "76%", value: "76"}],
                                        presentation: "8 ans"
                                    },
                                    {
                                        value: "9",
                                        label: "9 ans",
                                        options: [{label: "26.5%", value: "26.5"}, {label: "73.5%", value: "73.5"}],
                                        presentation: "9 ans"
                                    },
                                    {
                                        value: "10",
                                        label: "10 ans",
                                        options: [{label: "28%", value: "28"}, {label: "72%", value: "72"}],
                                        presentation: "10 ans"
                                    },
                                    {
                                        value: "11",
                                        label: "11 ans",
                                        options: [{label: "29.5%", value: "29.5"}, {label: "70.5%", value: "70.5"}],
                                        presentation: "11 ans"
                                    },
                                    {
                                        value: "12",
                                        label: "12 ans",
                                        options: [{label: "31%", value: "31"}, {label: "69%", value: "69"}],
                                        presentation: "12 ans"
                                    },
                                    {
                                        value: "13",
                                        label: "13 ans",
                                        options: [{label: "31.5%", value: "31.5"}, {label: "68.5%", value: "68.5"}],
                                        presentation: "13 ans"
                                    },
                                    {
                                        value: "14",
                                        label: "14 ans",
                                        options: [{label: "32.5%", value: "32.5"}, {label: "67.5%", value: "67.5"}],
                                        presentation: "14 ans"
                                    },
                                    {
                                        value: "15",
                                        label: "15 ans",
                                        options: [{label: "33.5%", value: "33.5"}, {label: "66.5%", value: "66.5"}],
                                        presentation: "15 ans"
                                    },
                                    {
                                        value: "16",
                                        label: "16 ans",
                                        options: [{label: "34.5%", value: "34.5"}, {label: "65.5%", value: "65.5"}],
                                        presentation: "16 ans"
                                    },
                                    {
                                        value: "17",
                                        label: "17 ans",
                                        options: [{label: "35.5%", value: "33.5"}, {label: "64.5%", value: "64.5"}],
                                        presentation: "17 ans"
                                    },
                                    {
                                        value: "18",
                                        label: "18 ans",
                                        options: [{label: "36.5%", value: "36.5"}, {label: "63.5%", value: "63.5"}],
                                        presentation: "18 ans"
                                    },
                                    {
                                        value: "19",
                                        label: "19 ans",
                                        options: [{label: "37%", value: "37"}, {label: "63%", value: "63"}],
                                        presentation: "19 ans"
                                    },
                                    {
                                        value: "20",
                                        label: "20 ans",
                                        options: [{label: "37.5%", value: "37.5"}, {label: "62.5%", value: "62.5"}],
                                        presentation: "20 ans"
                                    },
                                ]
                            }
                        }
                        break
                    // .................................................. Démembrement temporaire : Taux Nu-Propriétaire
                    case "c415f4b9-cee3-4b21-9760-140f5e15a9dc":
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_1</input_id>`
                        break

                    // ...................................... Démembrement temporaire : Montant Unitaire Nu-Propriétaire
                    case "f64a415f-8a50-4951-ab97-2254342bf891":
                        calculate = "(<input_id>c415f4b9-cee3-4b21-9760-140f5e15a9dc</input_id>"
                        calculate += ` / 100) * ${chosenProductUnitPrice}`
                        break

                    // ............................................... Démembrement temporaire : Montant Nu-Propriétaire
                    case "f64a415f-8a50-4978-ab97-2254352bf891":
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_1</input_id>`
                        calculate += ` / 100) * (${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${VOISIN_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>)`
                        break

                    // ............................. Démembrement temporaire : Montant Nu-Propriétaire en toutes lettres
                    case "145b9e20-80b6-476b-ad4a-27a03b048abc":
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = "f64a415f-8a50-4978-ab97-2254352bf891"
                        break

                    // ...................................................... Démembrement temporaire : Taux Usufruitier
                    case "85d55bd0-d3e5-4d90-af44-fa78acc4821e":
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id>`
                        break

                    // .......................................... Démembrement temporaire : Montant Unitaire Usufruitier
                    case "f64a415f-8a50-5178-ab97-2254352bf891":
                        calculate = "(<input_id>85d55bd0-d3e5-4d90-af44-fa78acc4821e</input_id>"
                        calculate += ` / 100) * ${chosenProductUnitPrice}`
                        break

                    // ................................................... Démembrement temporaire : Montant Usufruitier
                    case "aa7cb769-37c2-4171-b751-8e8f2475a3b6":
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id>`
                        calculate += ` / 100) * (${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${VOISIN_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>)`
                        break

                    // ................................................... Démembrement temporaire : Montant Usufruitier
                    case "fe1de194-97ea-4a59-b0a2-1efb9b9d3178":
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id>`
                        calculate += ` / 100) * (${chosenProductUnitPrice}`
                        calculate += `* <input_id>${VOISIN_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>)`
                        break

                    // .................................................................................. Montant global
                    case VOISIN_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID:
                        calculate = `${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${VOISIN_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>`
                        break

                    // ................................................................ Montant global en toutes lettres
                    case VOISIN_PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = VOISIN_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID
                        break

                    // ........................................................................... Durée de démembrement
                    case VOISIN_TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}</input_id>`
                        break

                    // ............................................................................ Age de l"usufruitier
                    case "0f968665-7797-4aaf-a9d1-1aa493ea1e01":
                        calculate = "<input_id>027c92b4-a326-4cfd-8b83-e0e1efe1fb34</input_id>"
                        break

                    // ................................... H_montant-NP-dem-viager-toutes-lettres : ( en toutes lettres)
                    case "142b9e20-80b6-476b-ad4a-27a03b048abc":
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = "fe1de194-97ea-4a59-b0a2-1efb9b9d3178"
                        break

                    default:
                        break
                }

                return new Element(
                    item.id,
                    item.name,
                    type,
                    item.css_classes,
                    attributes,
                    condition,
                    calculate,
                    reference
                )
            }
        )
    }

    getSogenialMockedElements(elements, productCode: string, formData): Promise<Element[] | null> {

        const SOGENIAL_IBAN_ELEMENT_ID = "d72b0453-bbe6-4a6c-96be-47024fa56d5f";

        const SOGENIAL_SUBSCRIPTION_DETENTION_MODE_ELEMENT_ID = "6b584ecc-d751-4627-912b-acdf7dc8e093";
        const SOGENIAL_FULL_OWNERSHIP_DISMEMBERMENT_SUBSCRIPTION_DETENTION_MODE = "Pleine propriété";
        const SOGENIAL_KNOWN_COUNTERPART_SUBSCRIPTION_DETENTION_MODE = "contrepartie connue";
        const SOGENIAL_UNKNOWN_COUNTERPART_SUBSCRIPTION_DETENTION_MODE = "contrepartie inconnue";

        const SOGENIAL_SUBSCRIPTION_DISMEMBERMENT_TYPE_ELEMENT_ID = "8ca72336-f59f-46c6-bcb4-0e3256bbd237";
        const SOGENIAL_TEMPORARY_DISMEMBERMENT_SUBSCRIPTION_TYPE = "Temporaire";
        const SOGENIAL_LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE = "Viager";

        const SOGENIAL_PRODUCT_SHARES_NUMBER_ELEMENT_ID = "d918aff9-4005-4354-939b-6d15c2396b12";
        const SOGENIAL_PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID = "632b763d-42c5-4b92-a97a-106cc66634fd";
        const SOGENIAL_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID = "a0130dd5-ba96-4647-8088-9004028588d5";
        const SOGENIAL_PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID = "52ac6c96-0781-4773-9896-bc39784593ab";

        const SOGENIAL_PRODUCT_CODE_FOR_CDV = "CŒUR DE VILLE";
        const SOGENIAL_PRODUCT_CODE_FOR_CDE = "CŒUR D'EUROPE";
        const SOGENIAL_PRODUCT_CODE_FOR_CDR = "CŒUR DE REGIONS";

        const SOGENIAL_CDV_PART_UNIT_PRICE_ELEMENT_ID = "0b73f4f8-ab0f-4e11-9bb0-9fb9cf43ab42";
        const SOGENIAL_CDE_PART_UNIT_PRICE_ELEMENT_ID = "12ad6818-ba4e-433f-a5b6-605769ab5b39";
        const SOGENIAL_CDR_PART_UNIT_PRICE_ELEMENT_ID = "946eade7-5600-4874-a076-9da673e49719";

        const SOGENIAL_LIFE_DISMEMBERMENT_RATES_ELEMENT_ID = "eb075c2b-bc0b-4315-9a4b-fb097abbbeb6"
        const SOGENIAL_TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID = "a39d2179-53b8-442d-98a2-3b708c8f7ff4"
        const SOGENIAL_TEMPORARY_DISMEMBERMENT_CDV_RATES_ELEMENT_ID = "bdb19ec2-5316-45df-835b-3f6b8a4eafbe"
        const SOGENIAL_TEMPORARY_DISMEMBERMENT_CDE_RATES_ELEMENT_ID = "7487d531-6b72-488a-b429-e783f012cddf"
        const SOGENIAL_TEMPORARY_DISMEMBERMENT_CDR_RATES_ELEMENT_ID = "fad5d6f1-3797-4d98-8a3e-841c92d44fe4"

        const SOGENIAL_BARE_OWNERSHIP_RATE_ELEMENT_ID = "0258773a-ea75-4f37-a017-9572556c64fe"
        const SOGENIAL_BARE_OWNERSHIP_AMOUNT_ELEMENT_ID = "953d482d-db54-4915-b6b1-e91eb2d1d8b9"
        const SOGENIAL_BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID = "41171942-7972-4d9f-9506-c5329e6449b5"

        const SOGENIAL_USUFRUCT_RATE_ELEMENT_ID = "a5031694-59e1-4d3c-a688-01ea3f4bf52e"
        const SOGENIAL_USUFRUCT_AMOUNT_ELEMENT_ID = "11977206-c7ed-4ddd-ab13-4107eba2e121"
        const SOGENIAL_USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID = "68f0c2ca-2a0c-4f3f-a19b-4cd30dd814ed"

        const sogenialSubscriptionDetentionMode = formData
            ? formData[SOGENIAL_SUBSCRIPTION_DETENTION_MODE_ELEMENT_ID]
            : null

        const sogenialSubscriptionType = formData
            ? formData[SOGENIAL_SUBSCRIPTION_DISMEMBERMENT_TYPE_ELEMENT_ID]
            : null

        const lifeDismemberment = (sogenialSubscriptionType === SOGENIAL_LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE)
        const temporaryDismemberment = (sogenialSubscriptionType !== SOGENIAL_LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE)

        let CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = ''
        let CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = ''

        lifeDismemberment && (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = SOGENIAL_LIFE_DISMEMBERMENT_RATES_ELEMENT_ID)

        switch (productCode) {
            case SOGENIAL_PRODUCT_CODE_FOR_CDV:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = SOGENIAL_CDV_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = SOGENIAL_TEMPORARY_DISMEMBERMENT_CDV_RATES_ELEMENT_ID)
                break
            case SOGENIAL_PRODUCT_CODE_FOR_CDE:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = SOGENIAL_CDE_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = SOGENIAL_TEMPORARY_DISMEMBERMENT_CDE_RATES_ELEMENT_ID)
                break
            case SOGENIAL_PRODUCT_CODE_FOR_CDR:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = SOGENIAL_CDR_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = SOGENIAL_TEMPORARY_DISMEMBERMENT_CDR_RATES_ELEMENT_ID)
                break
            default:
        }

        const chosenProductUnitPriceElement = elements.find(
            (element) => element.id === CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID
        )

        const chosenProductUnitPrice = chosenProductUnitPriceElement !== undefined
            ? chosenProductUnitPriceElement.attributes.defaultValue
            : ''

        return elements.map(
            (item) => {

                let type = item.type
                let attributes = item.attributes
                const condition = item.condition
                let calculate = ''
                let reference = ''

                switch (item.id) {

                    // ............................................................................................ IBAN
                    case SOGENIAL_IBAN_ELEMENT_ID:
                        type = EElementType.INPUT_IBAN
                        break

                    // .................................................................. Tableau de démembrement viager
                    case SOGENIAL_LIFE_DISMEMBERMENT_RATES_ELEMENT_ID:
                        if (!lifeDismemberment) {
                            break
                        }
                        if (sogenialSubscriptionType !== SOGENIAL_LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "life_dismemberment",
                            label: "Tableau de démembrement viager",
                            readonly: false,
                            disabled: true,
                            required: false,
                            multiple: false,
                            options: VIAGER_RATES_TABLE_ATTRIBUTES_OPTIONS
                        }
                        break

                    // ..................................................................... Tableau de démembrement CDV
                    case SOGENIAL_TEMPORARY_DISMEMBERMENT_CDV_RATES_ELEMENT_ID:
                        if (productCode !== SOGENIAL_PRODUCT_CODE_FOR_CDV) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Usufruit', 'Nue-propriété'],
                                options: [
                                    {
                                        value: '3',
                                        label: '3 ans',
                                        options: [{label: '12.50%', value: '12.5'}, {label: '87.50%', value: '87.5'}],
                                        presentation: '3 ans'
                                    },
                                    {
                                        value: '4',
                                        label: '4 ans',
                                        options: [{label: '16.00%', value: '16'}, {label: '84.00%', value: '84'}],
                                        presentation: '4 ans'
                                    },
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '20.00%', value: '20'}, {label: '80.00%', value: '80'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '23.50%', value: '23.5'}, {label: '76.50%', value: '76.5'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '27.00%', value: '27'}, {label: '73.00%', value: '73'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '28.50%', value: '28.5'}, {label: '71.50%', value: '71.5'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '30.00%', value: '30'}, {label: '70.00%', value: '70'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '33.00%', value: '33'}, {label: '67.00%', value: '67'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '34.50%', value: '34.5'}, {label: '65.50%', value: '65.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '36.00%', value: '36'}, {label: '64.00%', value: '64'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '37.00%', value: '37'}, {label: '63.00%', value: '63'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '38.00%', value: '38'}, {label: '62.00%', value: '62'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '39.00%', value: '39'}, {label: '61.00%', value: '61'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '40.00%', value: '40'}, {label: '60.00%', value: '60'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '41.00%', value: '41'}, {
                                            label: '59.00%', value: '59'
                                        }],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '42.00%', value: '42'}, {label: '58.00%', value: '58'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '43.00%', value: '43'}, {label: '57.00%', value: '57'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '44.00%', value: '44'}, {label: '56.00%', value: '56'}],
                                        presentation: '20 ans'
                                    },
                                ]
                            },
                        }
                        break

                    // ..................................................................... Tableau de démembrement CDE
                    case SOGENIAL_TEMPORARY_DISMEMBERMENT_CDE_RATES_ELEMENT_ID:
                        if (productCode !== SOGENIAL_PRODUCT_CODE_FOR_CDE) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Usufruit', 'Nue-propriété'],
                                options: [
                                    {
                                        value: '3',
                                        label: '3 ans',
                                        options: [{label: '12.50%', value: '12.5'}, {label: '87.50%', value: '87.5'}],
                                        presentation: '3 ans'
                                    },
                                    {
                                        value: '4',
                                        label: '4 ans',
                                        options: [{label: '15.00%', value: '15'}, {label: '85.00%', value: '85'}],
                                        presentation: '4 ans'
                                    },
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '20.00%', value: '20'}, {label: '80.00%', value: '80'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '22.00%', value: '22'}, {label: '78.00%', value: '78'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '25.00%', value: '25'}, {label: '75.00%', value: '75'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '27.50%', value: '27.5'}, {label: '72.50%', value: '72.5'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '30.00%', value: '30'}, {label: '70.00%', value: '70'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '32.00%', value: '32'}, {label: '68.00%', value: '68'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '34.00%', value: '34'}, {label: '66.00%', value: '66'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '35.50%', value: '35.5'}, {label: '64.50%', value: '64.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '36.00%', value: '36'}, {label: '64.00%', value: '64'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '37.00%', value: '37'}, {label: '63.00%', value: '63'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '38.50%', value: '38.5'}, {label: '61.50%', value: '61.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '39.00%', value: '39'}, {label: '61.00%', value: '61'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '40.00%', value: '40'}, {label: '60.00%', value: '60'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '41.00%', value: '41'}, {label: '59.00%', value: '59'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '42.00%', value: '42'}, {label: '58.00%', value: '58'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '42.50%', value: '42.5'}, {label: '57.50%', value: '57.5'}],
                                        presentation: '20 ans'
                                    },
                                ]
                            },
                        }
                        break

                    // ..................................................................... Tableau de démembrement CDR
                    case SOGENIAL_TEMPORARY_DISMEMBERMENT_CDR_RATES_ELEMENT_ID:
                        if (productCode !== SOGENIAL_PRODUCT_CODE_FOR_CDR) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Usufruit', 'Nue-propriété'],
                                options: [
                                    {
                                        value: '3',
                                        label: '3 ans',
                                        options: [{label: '13.50%', value: '13.5'}, {label: '86.50%', value: '86.5'}],
                                        presentation: '3 ans'
                                    },
                                    {
                                        value: '4',
                                        label: '4 ans',
                                        options: [{label: '17.50%', value: '17.5'}, {label: '82.50%', value: '82.5'}],
                                        presentation: '4 ans'
                                    },
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '23.00%', value: '23'}, {label: '77.00%', value: '77'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '26.00%', value: '26'}, {label: '74.0%', value: '74'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '28.00%', value: '28'}, {label: '72.00%', value: '72'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '31.00%', value: '31'}, {label: '69.00%', value: '69'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '34.00%', value: '34'}, {label: '66.00%', value: '66'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '36.50%', value: '36.5'}, {label: '63.50%', value: '63.5'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '37.50%', value: '37.5'}, {label: '62.50%', value: '62.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '38.50%', value: '38.5'}, {label: '61.50%', value: '61.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '39.50%', value: '39.5'}, {label: '60.50%', value: '60.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '40.50%', value: '40.5'}, {label: '59.50%', value: '59.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '42.00%', value: '42'}, {label: '58.00%', value: '58'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '43.00%', value: '43'}, {label: '57.00%', value: '57'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '44.00%', value: '44'}, {label: '56.00%', value: '56'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '45.00%', value: '45'}, {label: '55.00%', value: '55'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '45.50%', value: '45.5'}, {label: '54.50%', value: '54.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '46.00%', value: '46'}, {label: '54.00%', value: '54'}],
                                        presentation: '20 ans'
                                    },
                                ]
                            },
                        }
                        break

                    // ............................................................. Nombre de parts (en toutes lettres)
                    case SOGENIAL_PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = SOGENIAL_PRODUCT_SHARES_NUMBER_ELEMENT_ID
                        break

                    // ............................................................................ Taux Nu-Propriétaire
                    case SOGENIAL_BARE_OWNERSHIP_RATE_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_1</input_id>`
                        break

                    // ......................................................................... Montant Nu-Propriétaire
                    case SOGENIAL_BARE_OWNERSHIP_AMOUNT_ELEMENT_ID:
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_1</input_id>`
                        calculate += ` / 100) * (${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${SOGENIAL_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>)`
                        break

                    // .................................................. Montant Nu-Propriétaire : ( en toutes lettres)
                    case SOGENIAL_BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = SOGENIAL_BARE_OWNERSHIP_AMOUNT_ELEMENT_ID
                        break

                    // ................................................................................ Taux Usufruitier
                    case SOGENIAL_USUFRUCT_RATE_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id>`
                        break

                    // ............................................................................. Montant Usufruitier
                    case SOGENIAL_USUFRUCT_AMOUNT_ELEMENT_ID:
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id>`
                        calculate += ` / 100) * (${chosenProductUnitPrice}`
                        calculate += `* <input_id>${SOGENIAL_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>)`
                        break

                    // ...................................................... Montant Usufruitier : ( en toutes lettres)
                    case SOGENIAL_USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = SOGENIAL_USUFRUCT_AMOUNT_ELEMENT_ID
                        break

                    // ........................................................................... Durée de démembrement
                    case SOGENIAL_TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}</input_id>`
                        break

                    // .................................................................................. Montant global
                    case SOGENIAL_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID:
                        calculate = `${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${SOGENIAL_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>`
                        break

                    // ........................................................... Montant global : ( en toutes lettres)
                    case SOGENIAL_PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = SOGENIAL_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID
                        break

                    default:
                        break
                }

                return new Element(
                    item.id,
                    item.name,
                    type,
                    item.css_classes,
                    attributes,
                    condition,
                    calculate,
                    reference
                )
            }
        )
    }

    getRemakeMockedElements(elements, productCode: string, formData): Promise<Element[] | null> {

        const REMAKE_IBAN_ELEMENT_ID = "3ef2c63b-776f-43df-871d-4e11e0b4f738";

        const REMAKE_SUBSCRIPTION_DETENTION_MODE_ELEMENT_ID = "5fb29627-89c9-4a69-9479-70eaf510ab16";
        const REMAKE_FULL_OWNERSHIP_DISMEMBERMENT_SUBSCRIPTION_DETENTION_MODE = "Pleine propriété";
        const REMAKE_KNOWN_COUNTERPART_SUBSCRIPTION_DETENTION_MODE = "contrepartie connue";
        const REMAKE_UNKNOWN_COUNTERPART_SUBSCRIPTION_DETENTION_MODE = "contrepartie inconnue";

        const REMAKE_PRODUCT_SHARES_NUMBER_ELEMENT_ID = "4009c05a-e422-4834-8186-54fa85c5919b";
        const REMAKE_PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID = "860c03b0-572d-4e2d-8061-87fef6bb9677";
        const REMAKE_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID = "e3562c0b-63d9-4ef8-a4c4-7642b81224f7";
        const REMAKE_PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID = "f96311bb-b8f1-43ab-9196-39ef85341563";

        const REMAKE_DEM_TEMP_USU_ELEMENT_ID = "f64a415f-8a50-5178-ab97-2254352bf891";
        const REMAKE_DEM_TEMP_NP_ELEMENT_ID = "f64a415f-8a50-4978-ab97-2254352bf891";

        /**
         * @todo Product should be set as of "public.produit.code_produit"
         * UPDATE subscription.option SET value = 'LI' WHERE option_id = '6ae60166-162a-4c2e-8d6a-f6ff0c6d83fc'
         */

        const REMAKE_PRODUCT_CODE_FOR_LI = "LI"; // ........................................................ REMAKE LIVE
        const REMAKE_PRODUCT_CODE_FOR_MS = "MS"; // ................................................... MA PREMIERE SCPI
        const REMAKE_PRODUCT_CODE_FOR_DS = "DS"; // .................................................... MA SECONDE SCPI
        const REMAKE_PRODUCT_CODE_FOR_IR = "IR"; // ............................................................... IMMO
        const REMAKE_PRODUCT_CODE_FOR_HP = "HP"; // ........................................................... PIERRE 1
        const REMAKE_PRODUCT_CODE_FOR_AL = "AL"; // .............................................................. SCPI1
        const REMAKE_PRODUCT_CODE_FOR_EF = "EF"; // .............................................................. EFI 3

        const REMAKE_LI_PART_UNIT_PRICE_ELEMENT_ID = "1dc2d45d-467d-4aae-95e1-70fea9943096";
        const REMAKE_MS_PART_UNIT_PRICE_ELEMENT_ID = "";
        const REMAKE_DS_PART_UNIT_PRICE_ELEMENT_ID = "";
        const REMAKE_IR_PART_UNIT_PRICE_ELEMENT_ID = "";
        const REMAKE_HP_PART_UNIT_PRICE_ELEMENT_ID = "";
        const REMAKE_AL_PART_UNIT_PRICE_ELEMENT_ID = "";
        const REMAKE_EF_PART_UNIT_PRICE_ELEMENT_ID = "";

        // ..................................................................... Dismemberment Use Case Mocking Elements

        const REMAKE_SUBSCRIPTION_DISMEMBERMENT_TYPE_ELEMENT_ID = "878934b9-8a9d-4516-8d20-b9cb69abde02";
        const REMAKE_TEMPORARY_DISMEMBERMENT_SUBSCRIPTION_TYPE = "Temporaire";
        const REMAKE_LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE = "Viager";

        const remakeSubscriptionType = formData
            ? formData[REMAKE_SUBSCRIPTION_DISMEMBERMENT_TYPE_ELEMENT_ID]
            : null

        const remakeSubscriptionDetentionMode = formData
            ? formData[REMAKE_SUBSCRIPTION_DETENTION_MODE_ELEMENT_ID]
            : null

        const REMAKE_LIFE_DISMEMBERMENT_RATES_ELEMENT_ID = "2e86f90e-5d7d-45ef-84e6-2de30bd44d12"
        const REMAKE_TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID = "0ced93a2-a8f5-4c43-8df6-2eca7fa4a3d8"
        const REMAKE_TEMPORARY_DISMEMBERMENT_LI_RATES_ELEMENT_ID = "1053eda7-28ea-46a3-8175-9f5f4b1d395a"
        const REMAKE_TEMPORARY_DISMEMBERMENT_DS_RATES_ELEMENT_ID = ""
        const REMAKE_TEMPORARY_DISMEMBERMENT_MS_RATES_ELEMENT_ID = ""
        const REMAKE_TEMPORARY_DISMEMBERMENT_IR_RATES_ELEMENT_ID = ""
        const REMAKE_TEMPORARY_DISMEMBERMENT_HP_RATES_ELEMENT_ID = ""
        const REMAKE_TEMPORARY_DISMEMBERMENT_AL_RATES_ELEMENT_ID = ""
        const REMAKE_TEMPORARY_DISMEMBERMENT_EF_RATES_ELEMENT_ID = ""

        const REMAKE_BARE_OWNERSHIP_RATE_ELEMENT_ID = "5d545b60-4bd2-49a7-b92d-bbf04ba7c312"
        const REMAKE_BARE_OWNERSHIP_AMOUNT_ELEMENT_ID = "89386a85-e0a3-4079-8062-c2232a15bc07"
        const REMAKE_BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID = "41171942-7972-4d9f-9506-c5329e6449b5"

        const REMAKE_USUFRUCT_RATE_ELEMENT_ID = "acdde060-5f73-4872-8189-ef0abe90721c"
        const REMAKE_USUFRUCT_AMOUNT_ELEMENT_ID = "df0123b1-b315-4603-a564-e9ab7469b3c1"
        const REMAKE_USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID = "68f0c2ca-2a0c-4f3f-a19b-4cd30dd814ed"


        const lifeDismemberment = (remakeSubscriptionType === REMAKE_LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE)
        const temporaryDismemberment = (remakeSubscriptionType !== REMAKE_LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE)

        let CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = ''
        let CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = ''

        lifeDismemberment && (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = REMAKE_LIFE_DISMEMBERMENT_RATES_ELEMENT_ID)

        switch (productCode) {
            case REMAKE_PRODUCT_CODE_FOR_LI:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = REMAKE_LI_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = REMAKE_TEMPORARY_DISMEMBERMENT_LI_RATES_ELEMENT_ID)
                break
            case REMAKE_PRODUCT_CODE_FOR_MS:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = REMAKE_MS_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = REMAKE_TEMPORARY_DISMEMBERMENT_MS_RATES_ELEMENT_ID)
                break
            case REMAKE_PRODUCT_CODE_FOR_DS:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = REMAKE_DS_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = REMAKE_TEMPORARY_DISMEMBERMENT_DS_RATES_ELEMENT_ID)
                break
            case REMAKE_PRODUCT_CODE_FOR_IR:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = REMAKE_IR_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = REMAKE_TEMPORARY_DISMEMBERMENT_IR_RATES_ELEMENT_ID)
                break
            case REMAKE_PRODUCT_CODE_FOR_HP:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = REMAKE_HP_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = REMAKE_TEMPORARY_DISMEMBERMENT_HP_RATES_ELEMENT_ID)
                break
            case REMAKE_PRODUCT_CODE_FOR_AL:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = REMAKE_AL_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = REMAKE_TEMPORARY_DISMEMBERMENT_AL_RATES_ELEMENT_ID)
                break
            case REMAKE_PRODUCT_CODE_FOR_EF:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = REMAKE_EF_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = REMAKE_TEMPORARY_DISMEMBERMENT_EF_RATES_ELEMENT_ID)
                break
            default:
        }

        const chosenProductUnitPriceElement = elements.find(
            (element) => element.id === CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID
        )

        const chosenProductUnitPrice = chosenProductUnitPriceElement !== undefined
            ? chosenProductUnitPriceElement.attributes.defaultValue
            : ''

        return elements.map(
            (item) => {

                let type = item.type
                let attributes = item.attributes
                const condition = item.condition
                let calculate = ''
                let reference = ''

                switch (item.id) {

                    case "e176f745-ec12-49d3-92aa-0a8e5b63986c":
                        type = "button_create_prospect"
                        break

                    // ............................................................................................ IBAN
                    case REMAKE_IBAN_ELEMENT_ID:
                        type = EElementType.INPUT_IBAN
                        break

                    // .................................................................. Tableau de démembrement viager
                    case REMAKE_LIFE_DISMEMBERMENT_RATES_ELEMENT_ID:
                        if (!lifeDismemberment) {
                            break
                        }
                        if (remakeSubscriptionType !== REMAKE_LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "life_dismemberment",
                            label: "Tableau de démembrement viager",
                            readonly: false,
                            disabled: true,
                            required: false,
                            multiple: false,
                            options: VIAGER_RATES_TABLE_ATTRIBUTES_OPTIONS
                        }
                        break

                    // ...................................................................... Tableau de démembrement LI
                    case REMAKE_TEMPORARY_DISMEMBERMENT_LI_RATES_ELEMENT_ID:
                        if (productCode !== REMAKE_PRODUCT_CODE_FOR_LI) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement DS
                    case REMAKE_TEMPORARY_DISMEMBERMENT_DS_RATES_ELEMENT_ID:
                        if (productCode !== REMAKE_PRODUCT_CODE_FOR_DS) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement MS
                    case REMAKE_TEMPORARY_DISMEMBERMENT_MS_RATES_ELEMENT_ID:
                        if (productCode !== REMAKE_PRODUCT_CODE_FOR_MS) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement IR
                    case REMAKE_TEMPORARY_DISMEMBERMENT_IR_RATES_ELEMENT_ID:
                        if (productCode !== REMAKE_PRODUCT_CODE_FOR_IR) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement HP
                    case REMAKE_TEMPORARY_DISMEMBERMENT_HP_RATES_ELEMENT_ID:
                        if (productCode !== REMAKE_PRODUCT_CODE_FOR_HP) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement AL
                    case REMAKE_TEMPORARY_DISMEMBERMENT_AL_RATES_ELEMENT_ID:
                        if (productCode !== REMAKE_PRODUCT_CODE_FOR_AL) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement EF
                    case REMAKE_TEMPORARY_DISMEMBERMENT_EF_RATES_ELEMENT_ID:
                        if (productCode !== REMAKE_PRODUCT_CODE_FOR_EF) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ............................................................. Nombre de parts (en toutes lettres)
                    case REMAKE_PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = REMAKE_PRODUCT_SHARES_NUMBER_ELEMENT_ID
                        break

                    // ............................................................................ Taux Nu-Propriétaire
                    case REMAKE_BARE_OWNERSHIP_RATE_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id>`
                        break

                    // ......................................................................... Montant Nu-Propriétaire
                    case REMAKE_BARE_OWNERSHIP_AMOUNT_ELEMENT_ID:
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id>`
                        calculate += ` / 100) * (${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${REMAKE_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>)`
                        break

                    // .................................................. Montant Nu-Propriétaire : ( en toutes lettres)
                    case REMAKE_BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = REMAKE_BARE_OWNERSHIP_AMOUNT_ELEMENT_ID
                        break

                    // ................................................................................ Taux Usufruitier
                    case REMAKE_USUFRUCT_RATE_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_1</input_id>`
                        break

                    // ............................................................................. Montant Usufruitier
                    case REMAKE_USUFRUCT_AMOUNT_ELEMENT_ID:
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_1</input_id>`
                        calculate += ` / 100) * (${chosenProductUnitPrice}`
                        calculate += `* <input_id>${REMAKE_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>)`
                        break

                    // ...................................................... Montant Usufruitier : ( en toutes lettres)
                    case REMAKE_USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = REMAKE_USUFRUCT_AMOUNT_ELEMENT_ID
                        break

                    // ........................................................................... Durée de démembrement
                    case REMAKE_TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}</input_id>`
                        break

                    // .................................................................................. Montant global
                    case REMAKE_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID:
                        calculate = `${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${REMAKE_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>`
                        break

                    // ...................................... Démembrement temporaire : Montant Unitaire Nu-Propriétaire
                    case REMAKE_DEM_TEMP_NP_ELEMENT_ID:
                        calculate = "(<input_id>5d545b60-4bd2-49a7-b92d-bbf04ba7c312</input_id>"
                        calculate += ` / 100) * ${chosenProductUnitPrice}`
                        break

                    // ...................................... Démembrement temporaire : Montant Unitaire Usu
                    case REMAKE_DEM_TEMP_USU_ELEMENT_ID:
                        calculate = "(<input_id>acdde060-5f73-4872-8189-ef0abe90721c</input_id>"
                        calculate += ` / 100) * ${chosenProductUnitPrice}`
                        break

                    // ........................................................... Montant global : ( en toutes lettres)
                    case REMAKE_PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = REMAKE_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID
                        break

                    default:
                        break
                }

                return new Element(
                    item.id,
                    item.name,
                    type,
                    item.css_classes,
                    attributes,
                    condition,
                    calculate,
                    reference
                )
            }
        )

    }

    getSwisslifeMockedElements(elements, productCode: string, formData): Promise<Element[] | null> {

        const SWISSLIFE_IBAN_ELEMENT_ID = "3ef2c63b-776f-43df-871d-4e11e0b4f738";
        const SWISSLIFE_SHARES_NUMBER_ID = "4009c05a-e422-4834-8186-54fa85c5919b";
        const SWISSLIFE_SHARES_NUMBER_IN_LETTERS_ID = "860c03b0-572d-4e2d-8061-87fef6bb9677";
        const SWISSLIFE_TOTAL_AMOUNT_ID = "e3562c0b-63d9-4ef8-a4c4-7642b81224f7";
        const SWISSLIFE_TOTAL_AMOUNT_IN_LETTERS_ID = "f96311bb-b8f1-43ab-9196-39ef85341563";
        const SWISSLIFE_UNIT_PRICE_ID = "1dc2d45d-467d-4aae-95e1-70fea9943096";

        return elements.map(
            (item) => {

                let type = item.type
                const attributes = item.attributes
                const condition = item.condition
                let calculate = ""
                let reference = ""

                switch (item.id) {

                    // .................................................................................... IBAN
                    case SWISSLIFE_IBAN_ELEMENT_ID:
                        type = EElementType.INPUT_IBAN
                        break
                    case SWISSLIFE_TOTAL_AMOUNT_ID:
                        calculate = `<input_id>${SWISSLIFE_SHARES_NUMBER_ID}</input_id>`
                        calculate += `* <input_id>${SWISSLIFE_UNIT_PRICE_ID}</input_id>`
                        break
                    case SWISSLIFE_SHARES_NUMBER_IN_LETTERS_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD;
                        reference = SWISSLIFE_SHARES_NUMBER_ID;
                        break
                    case SWISSLIFE_TOTAL_AMOUNT_IN_LETTERS_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD;
                        reference = SWISSLIFE_TOTAL_AMOUNT_ID;
                        break
                    default:
                        break
                }

                return new Element(
                    item.id,
                    item.name,
                    type,
                    item.css_classes,
                    attributes,
                    condition,
                    calculate,
                    reference
                )
            }
        )

    }

    /**
     * Returns Mocked or not Elements if mentioned by customer specifics mock
     * @param blockId
     * @param productCode
     * @param customer
     */
    async getElementsByBlockId(
        blockId: string,
        productCode: string,
        customer: string
    ): Promise<Element[] | null> {

        return Caller.executeGet(
            `${envVariable("REACT_APP_API_URL")}/${envVariable("REACT_APP_LOCALE")}/blocks/${blockId}/elements`,
            {}
        ).then(
            data => {
                if (!data) {
                    return null
                }
                const jsonFormData = localStorage.getItem('form')
                const formData = jsonFormData ? JSON.parse(jsonFormData) : null

                let mockedElements
                switch (customer) {
                    case 'voisin':
                        mockedElements = this.getVoisinMockedElements(data.elements, productCode, formData)
                        break
                    case 'sogenial':
                        mockedElements = this.getSogenialMockedElements(data.elements, productCode, formData)
                        break
                    case 'remake':
                        mockedElements = this.getRemakeMockedElements(data.elements, productCode, formData)
                        break
                    case 'swisslife-am':
                        mockedElements = this.getSwisslifeMockedElements(data.elements, productCode, formData)
                        break
                    default:
                        break
                }
                return mockedElements
            }
        ).catch(() => null);
    }

}
